@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-picker {
  width: 100% !important;
}

.ant-table-cell {
  text-align: center !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center !important;
  vertical-align: middle !important;
}
